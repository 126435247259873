<template>
  <div class="page-simplePage-list">
    <a-card :bordered="false">
      这个页面将被缓存，跳转到详情，input内容会保存。需要重载得话，需要切换目录，或在跳转前手动调用this.$routex.refreshNext()
      <a-input placeholder="输入的内容将被缓存"></a-input>
      <a-button @click="toDetail">跳转详情</a-button>
    </a-card>
  </div>
</template>

<script>
export default {
  methods:{
    toDetail(){
      this.$router.push('/m/keepAlivePage/main/detail')
    }
  }
}
</script>

<style lang="less" scoped>
</style>
